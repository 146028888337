/* eslint-disable import/prefer-default-export */

// import node_modules
import * as React from 'react';
import { useImmer } from 'use-immer';

// import types
import { Theme } from '../types';

// define hooks
export const useTheme = (): {
  setTheme: (theme: Theme) => void;
  theme: Theme;
  toggleTheme: () => void;
} => {
  // init state
  const [themeState, setThemeState] = useImmer<Theme>('light');

  // init func
  const onToggleTheme = React.useCallback(() => {
    if (localStorage.theme === 'dark') {
      localStorage.setItem('theme', 'light');
      const html = document.querySelector('html');
      if (html) {
        html.classList.remove('dark');
      }
      setThemeState('light');
    } else {
      localStorage.setItem('theme', 'dark');
      const html = document.querySelector('html');
      if (html) {
        html.classList.add('dark');
      }
      setThemeState('dark');
    }
  }, [setThemeState]);

  const onSetTheme = React.useCallback(
    (theme: Theme) => {
      // set dark theme
      if (theme === 'dark' && themeState !== theme) {
        localStorage.setItem('theme', theme);
        const html = document.querySelector('html');
        if (html) {
          html.classList.add('dark');
        }
        setThemeState(theme);
      }

      // set light theme
      if (theme === 'light' && themeState !== theme) {
        localStorage.setItem('theme', theme);
        const html = document.querySelector('html');
        if (html) {
          html.classList.remove('dark');
        }
        setThemeState(theme);
      }
    },
    [setThemeState, themeState],
  );

  React.useEffect(() => {
    setThemeState((typeof localStorage !== 'undefined' && localStorage?.theme) || 'light');
  }, [setThemeState]);

  // return hook
  return {
    setTheme: onSetTheme,
    theme: themeState,
    toggleTheme: onToggleTheme,
  };
};

export default useTheme;

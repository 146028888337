/* eslint-disable @typescript-eslint/ban-ts-comment */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import isArray from 'lodash/isArray';
import { Button, Heading, LanguageSwitcher, RemixIcon, Text } from '@storylinerlabs/design';
import { useImmerAtom } from 'jotai/immer';
import { useTranslation } from 'react-i18next';

// import types
import { Locale } from '../../types';

// import components
// import StorylinerImage from '../atoms/StorylinerImage';
import LinkComponent from '../atoms/LinkComponent';
import ThemeSwitcher from '../atoms/ThemeSwitcher';

// import modules
import { localeAtom } from '../../modules/jotai';

// import config
import { storylinerWebAppDomain } from '../../../config';

// import styles
import styles from './Footer.module.css';

// FooterComponent component
const FooterComponent: React.FunctionComponent<{ changeLocale: (locale: Locale) => void }> = ({
  changeLocale,
}) => {
  // init hooks
  const [locale] = useImmerAtom(localeAtom);
  const { t } = useTranslation('navigations');

  // init func
  const onGoBackToTop = React.useCallback((event: MouseEvent | TouchEvent) => {
    // prevent default
    event.preventDefault();

    // get element and scroll to top
    const element = document.getElementById('top');
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  const onOpenCookieSettings = React.useCallback(() => {
    // @ts-ignore
    window?.cookiehub?.openSettings();
  }, []);

  // init render
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const columns: { title: string; links: { href: string; label: string }[] }[] = t(
    'Footer.columns',
    { returnObjects: true },
  );
  const socialMediaLinks: { href: string; label: string; icon: string }[] = t(
    'Footer.socialLinks',
    {
      returnObjects: true,
    },
  );

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {isArray(columns) &&
          columns?.map((column, index, array) => (
            <div className={styles.column} key={column.title}>
              <Heading tag="label">{column.title}</Heading>
              <div className={styles.columnMenu}>
                {column.links.map((link) => {
                  // NOTE: https://support.cookiehub.com/hc/en-us/articles/360041344172-Opening-the-cookie-settings-dialog
                  if (link?.href?.includes('cookie')) {
                    return (
                      <div className={styles.columnMenuItem} key={link.label}>
                        <button
                          className={styles.columnMenuLink}
                          onClick={onOpenCookieSettings}
                          type="button"
                        >
                          {link.label}
                        </button>
                      </div>
                    );
                  }
                  if (link?.href?.includes('/auth/')) {
                    return (
                      <div className={styles.columnMenuItem} key={link.label}>
                        <a
                          className={styles.columnMenuLink}
                          href={`${storylinerWebAppDomain}${link.href}`}
                          rel="noopener noreferrer"
                          target="_self"
                          title={link.label}
                        >
                          {link.label}
                        </a>
                      </div>
                    );
                  }
                  if (link?.href?.includes('://')) {
                    return (
                      <div className={styles.columnMenuItem} key={link.label}>
                        <a
                          className={styles.columnMenuLink}
                          href={link.href}
                          rel="noopener noreferrer"
                          target="_blank"
                          title={link.label}
                        >
                          {link.label}
                        </a>
                      </div>
                    );
                  }
                  if (link?.href?.includes('mailto:')) {
                    return (
                      <div className={styles.columnMenuItem} key={link.label}>
                        <a className={styles.columnMenuLink} href={link.href}>
                          {link.label}
                        </a>
                      </div>
                    );
                  }
                  return (
                    <div className={styles.columnMenuItem} key={link.label}>
                      <LinkComponent href={link.href}>{link.label}</LinkComponent>
                    </div>
                  );
                })}
              </div>

              {array.length - 1 === index && (
                <div className={styles.socialMediaMenu}>
                  {socialMediaLinks.map((socialLink) => (
                    <a
                      className={styles.socialMediaLink}
                      href={socialLink.href}
                      key={socialLink.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      title={socialLink.label}
                    >
                      <RemixIcon iconName={socialLink.icon} />
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>

      <div className={styles.socketContainer}>
        <div className={styles.socketMenu}>
          <Text size="small">{t('Footer.socket.copyright.label', { currentYear })}</Text>
        </div>

        <div className={styles.socketMenu}>
          <Text size="small">{t('Footer.socket.location.label')}</Text>
          <RemixIcon iconName="ri-anchor-line" />
        </div>

        <div className={styles.socketMenu}>
          <Button
            color="naked"
            icon="ri-arrow-up-line"
            onClick={onGoBackToTop}
            size="extraSmall"
            text={t('Footer.socket.toTop.label')}
          />
          <LanguageSwitcher locale={locale} changeLocale={changeLocale} isSmall />
          <ThemeSwitcher />
        </div>
      </div>
    </div>
  );
};

FooterComponent.propTypes = {
  changeLocale: PropTypes.func.isRequired,
};

FooterComponent.displayName = 'Footer';

export default FooterComponent;

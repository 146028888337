/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-danger */

// import node_modules
import * as React from 'react';
import { Button, Form, Input, RemixIcon, Text, Tooltip } from '@storylinerlabs/design';
import { useImmer } from 'use-immer';
import { useImmerAtom } from 'jotai/immer';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '@storylinerlabs/notifications';

// import types
import { RouteEnum } from '../../types';

// import modules
import { earlyAccessModalAtom, localeAtom } from '../../modules/jotai';
import { isEmail } from '../../modules/utils';

// import styles
import styles from './EarlyAccessForm.module.css';

// define component
const EarlyAccessForm: React.FunctionComponent = () => {
  // init state
  const [inputState, setInputState] = useImmer<string>('');
  const [errorState, setErrorState] = useImmer<string | null>(null);
  const [loadingState, setLoadingState] = useImmer<boolean>(false);

  // init hooks
  const [, setEarlyAccessModalState] = useImmerAtom(earlyAccessModalAtom);
  const [locale] = useImmerAtom(localeAtom);
  const router = useRouter();
  const { t } = useTranslation('components');
  const { subscribeToEarlyAccessWithDoubleOptin } = useNotifications();

  // define methods
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputState(event.target.value);
    },
    [setInputState],
  );

  const onSubmit = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      // show spinner
      setLoadingState(true);

      // check email
      if (!isEmail(inputState)) {
        setErrorState(t('EarlyAccessForm.email.error'));
        setLoadingState(false);
        return;
      }

      try {
        // reset error
        setErrorState(null);

        // fetch api
        const { error, success } = await subscribeToEarlyAccessWithDoubleOptin({
          email: inputState,
          locale,
        });

        // check error
        if (error || !success) {
          throw new Error('Subscription failed!');
        }

        // redirect to success page
        const isDE = locale === 'de';
        const thankYouPage = isDE
          ? RouteEnum.WEBSITE_EARLY_ACCESS_THANK_YOU_DE
          : RouteEnum.WEBSITE_EARLY_ACCESS_THANK_YOU_EN;
        router.push(thankYouPage);

        // hide spinner and close modal
        setEarlyAccessModalState(() => false);
        setLoadingState(false);
        setInputState('');
      } catch (error) {
        setErrorState(t('EarlyAccessForm.submit.error'));
        setLoadingState(false);
      }
    },
    [
      inputState,
      locale,
      router,
      setEarlyAccessModalState,
      setErrorState,
      setInputState,
      setLoadingState,
      subscribeToEarlyAccessWithDoubleOptin,
      t,
    ],
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // handle return
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit(event);
      }
    },
    [onSubmit],
  );

  // render
  return (
    <div className={styles['storyliner-early-access-form']}>
      <div className={styles['storyliner-early-access-form-container']}>
        <Form formId="storyliner-early-access-form" onSubmit={onSubmit}>
          <div className={styles['storyliner-early-access-form-input-container']}>
            <Input
              formId="storyliner-early-access-form"
              inputId="storyliner-early-access-form-intput"
              inputName="storyliner-early-access-form-intput"
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={t('EarlyAccessForm.email.placeholder')}
              type="email"
              value={inputState}
            />
            <Button
              color="dark"
              formId="storyliner-early-access-form"
              isLoading={loadingState}
              text={t('EarlyAccessForm.submit.label')}
              type="submit"
            />
          </div>
          <div className={styles['storyliner-early-access-form-error-container']}>
            {errorState && <Text isError>{errorState}</Text>}
          </div>
        </Form>
      </div>
      <div className={styles['storyliner-early-access-form-privacy-container']}>
        <RemixIcon iconName="ri-lock-line" />
        <Tooltip placement="top" content={t('EarlyAccessForm.privacy.tooltip')}>
          <Text size="small" isHelpText>
            {t('EarlyAccessForm.privacy.text')}
          </Text>
        </Tooltip>
      </div>
    </div>
  );
};

EarlyAccessForm.displayName = 'EarlyAccessForm';

export default EarlyAccessForm;

// import node_modules
import * as React from 'react';
import { Heading, ModalContainer, Modal, Text } from '@storylinerlabs/design';
import { useTranslation } from 'react-i18next';
import { useImmerAtom } from 'jotai/immer';

// import components
import EarlyAccessForm from '../forms/EarlyAccessForm';

// import modules
import { earlyAccessModalAtom, localeAtom } from '../../modules/jotai';

// import styles
import styles from './EarlyAccessModal.module.css';

// define component
const EarlyAccessModal: React.FunctionComponent = () => {
  // init hooks
  const [earlyAccessModalState, setEarlyAccessModalState] = useImmerAtom(earlyAccessModalAtom);
  const [locale] = useImmerAtom(localeAtom);
  const { t } = useTranslation('components');

  // init func
  const onCloseModal = React.useCallback(() => {
    setEarlyAccessModalState(() => false);
  }, [setEarlyAccessModalState]);

  // render modal
  return (
    <ModalContainer isActive={earlyAccessModalState} closeModal={onCloseModal}>
      <Modal isActive={earlyAccessModalState} closeModal={onCloseModal} locale={locale}>
        <div className={styles['storyliner-early-access-modal-content']}>
          <div className={styles['storyliner-early-access-modal-header-container']}>
            <Heading alignment="center" tag="h3">
              {t('EarlyAccessModal.title')}
            </Heading>
            <Text alignment="center">{t('EarlyAccessModal.text')}</Text>
          </div>
          <div className={styles['storyliner-early-access-modal-form-container']}>
            <EarlyAccessForm />
          </div>
        </div>
      </Modal>
    </ModalContainer>
  );
};

export default EarlyAccessModal;

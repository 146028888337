// import node_modules
import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

// import styles
import styles from './Column.module.css';

// define component
export const Column: React.FunctionComponent<{
  children: React.ReactNode;
  className?: string;
  cols?: number | string;
  isPaddingless?: boolean;
}> = ({ children, className, cols, isPaddingless }) => (
  <div className={classNames(styles['column'], cols && styles[`col-${cols}`], className)}>
    <div className={classNames(styles['container'], isPaddingless && styles['is-paddingless'])}>
      {children}
    </div>
  </div>
);

Column.defaultProps = {
  className: undefined,
  cols: 'full',
  isPaddingless: undefined,
};

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPaddingless: PropTypes.bool,
};

export default Column;

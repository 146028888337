// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Link from 'next/link';

// define component
const LinkComponent: React.FunctionComponent<{
  ariaLabel?: string;
  children: React.ReactNode;
  href: string;
  target?: string;
  title?: string;
}> = ({ ariaLabel, children, href, target, title }) => {
  // external link
  if (href?.includes('http') || href?.includes('mailto')) {
    return (
      <a
        aria-label={ariaLabel}
        className="no-underline"
        href={href}
        rel="noopener noreferrer"
        target={target}
        title={title}
      >
        {children}
      </a>
    );
  }

  return (
    <Link aria-label={ariaLabel} href={href} passHref title={title}>
      {children}
    </Link>
  );
};

LinkComponent.defaultProps = {
  ariaLabel: undefined,
  target: '_blank',
  title: undefined,
};

LinkComponent.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

export default LinkComponent;

// import node_modules
import * as React from 'react';
import { Button, Tooltip } from '@storylinerlabs/design';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

// import types
import { Theme } from '../../types';

// import modules
import { useTheme } from '../../hooks/use-theme';

// define components
const ThemeSwitcher: React.FunctionComponent = () => {
  // init hooks
  const { t } = useTranslation('components');
  const { theme, toggleTheme } = useTheme();

  // init states
  const [themeState, setThemeState] = useImmer<Theme>('light');

  // NOTE: this is due to SSR
  React.useEffect(() => {
    setThemeState(theme);
  }, [setThemeState, theme]);

  // init render
  return (
    <Tooltip
      content={themeState === 'dark' ? t('ThemeSwitcher.dark') : t('ThemeSwitcher.light')}
      placement="top"
    >
      <Button
        color="ultraLight"
        icon="ri-contrast-2-fill"
        onClick={toggleTheme}
        size="extraSmall"
      />
    </Tooltip>
  );
};

ThemeSwitcher.displayName = 'ThemeSwitcher';

export default ThemeSwitcher;

// import node_modules
import * as React from 'react';
import * as PropTypes from 'prop-types';

// import styles
import styles from './Section.module.css';
import classNames from 'classnames';

// define types
export type SectionColor =
  | 'allports'
  | 'anakiwa'
  | 'desertStorm'
  | 'energyYellow'
  | 'java'
  | 'tuatara'
  | 'white';

export enum SectionColorEnum {
  allports = 'allports',
  anakiwa = 'anakiwa',
  desertStorm = 'desertStorm',
  energyYellow = 'energyYellow',
  java = 'java',
  tuatara = 'tuatara',
  white = 'white',
}

export type SectionAlignment = 'bottom' | 'middle' | 'top';

export enum SectionAlignmentEnum {
  bottom = 'bottom',
  middle = 'middle',
  top = 'top',
}

export type SectionFlexDirection = 'column' | 'row' | 'row-reverse' | 'column-reverse';

export enum SectionFlexDirectionEnum {
  column = 'column',
  columnReverse = 'column-reverse',
  row = 'row',
  rowReverse = 'row-reverse',
}

// define component
export const Section: React.FunctionComponent<{
  bgColor?: SectionColor;
  bgImage?: string;
  bgImageDark?: string;
  bgPosition?: string;
  children: React.ReactNode;
  isPaddingLess?: boolean;
  mobileFlexDirection?: SectionFlexDirection;
  sectionId?: string;
  tabletFlexDirection?: SectionFlexDirection;
  verticalAlignment?: SectionAlignment;
  zIndex?: number;
}> = ({
  bgColor,
  bgImage,
  bgImageDark,
  bgPosition,
  children,
  isPaddingLess,
  mobileFlexDirection,
  sectionId,
  tabletFlexDirection,
  verticalAlignment,
  zIndex,
}) => (
  <section
    className={classNames(
      styles['section'],
      bgColor && styles[bgColor as string],
      bgImage && styles[bgImage as string],
      bgImageDark && styles[`${bgImageDark}-dark` as string],
    )}
    id={sectionId}
    style={{ backgroundPosition: bgPosition || 'center', zIndex: zIndex || 0 }}
  >
    <div
      className={classNames(
        styles['section-container'],
        verticalAlignment && styles[`is-${verticalAlignment}`],
        mobileFlexDirection && styles[`is-${mobileFlexDirection}-mobile`],
        tabletFlexDirection && styles[`is-${tabletFlexDirection}-tablet`],
        isPaddingLess && styles['is-paddingless'],
      )}
    >
      {children}
    </div>
  </section>
);

Section.defaultProps = {
  bgColor: 'white',
  bgImage: undefined,
  bgImageDark: undefined,
  bgPosition: undefined,
  isPaddingLess: undefined,
  mobileFlexDirection: undefined,
  sectionId: '',
  tabletFlexDirection: undefined,
  verticalAlignment: 'middle',
  zIndex: 0,
};

Section.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(SectionColorEnum)),
  bgImage: PropTypes.string,
  bgImageDark: PropTypes.string,
  bgPosition: PropTypes.string,
  children: PropTypes.node.isRequired,
  isPaddingLess: PropTypes.bool,
  mobileFlexDirection: PropTypes.oneOf(Object.values(SectionFlexDirectionEnum)),
  sectionId: PropTypes.string,
  tabletFlexDirection: PropTypes.oneOf(Object.values(SectionFlexDirectionEnum)),
  verticalAlignment: PropTypes.oneOf(Object.values(SectionAlignmentEnum)),
  zIndex: PropTypes.number,
};

export default Section;
